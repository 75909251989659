<template id="home">
  <b-container fluid class="p-2">
    <b-card title="Home">
      <b-card-body>
        <b-img
          thumbnail
          :src="user.avatar_url"
          :alt="user.nomeCompleto"
          width="150"
          class="mb-3"
        />
        <p>
          Hello <span class="font-weight-bold">{{ user.nomeCompleto }}</span
          >,
        </p>
        <p>
          You are: <span style="color: #28a745">{{ gruppi }}</span>
        </p>
        <p>
          Last login:
          <span class="font-weight-bold">{{
            (last_login ? last_login.created_at : "") | formatDateHour
          }}</span>
        </p>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
export default {
  data() {
    return {
      last_login: null,
    };
  },

  components: {},

  methods: {},
  mounted() {},
  computed: {
    user: function () {
      return Auth.user;
    },
    gruppi: function () {
      var gruppi_lbl = "";
      for (var i in Auth.user.gruppi) {
        if (gruppi_lbl != "") {
          gruppi_lbl += ", ";
        }
        gruppi_lbl += Auth.user.gruppi[i].nome;
      }
      return gruppi_lbl;
    },
  },
  created: function () {
    this.$http.get("/last/login").then((response) => {
      this.last_login = response.data;
    });
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
